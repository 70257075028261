import EmptySearchIcon from '@/public/images/icons/EmptySearch.png';
import {
  LocationSelectorContext,
  LocationSelectorContextProps,
} from '@/src/modules/resources/components//DestinationSelector/LocationSelectorContext';
import { LocationSelectorNode } from '@/src/modules/resources/components//DestinationSelector/LocationSelectorNode';
import { LocationSelectorNodeData } from '@/src/modules/resources/components//DestinationSelector/types';
import {
  folderToLocationSelectorNodeData,
  rootToLocationSelectorNodeData,
} from '@/src/modules/resources/components//DestinationSelector/utils';
import { useQueryResourceList } from '@/src/modules/resources/queries/useQueryResourceList';
import { isFolderFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { Flex } from '@/src/modules/ui/components/Flex';
import { IntersectionObserverAnchor } from '@/src/modules/ui/components/IntersectionObserver/IntersectionObserver';
import { P } from '@/src/modules/ui/components/Typography';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 1rem;
  background: ${cssVar['color-bg-secondary-button']};
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  & > *:first-child {
    border-top: none;
  }
`;

const SectionsWrapper = styled(Flex).attrs({
  gap: 'elementsContainer',
  direction: 'column',
})``;

export const LocationSelector: React.FC<
  LocationSelectorContextProps & {
    searchKeyword?: string;
    querySearchResources: ReturnType<typeof useQueryResourceList>;
  }
> = ({ searchKeyword = '', querySearchResources, ...contextValue }) => {
  const { spaceRoots } = useQueryResourceRootSpaceSystemList(undefined, { includeInbox: true });

  const spacesMapped: LocationSelectorNodeData[] = spaceRoots.map(rootToLocationSelectorNodeData);

  const searchResultSpaces = spacesMapped.filter((item) =>
    item.title.toLowerCase().includes(searchKeyword.toLowerCase()),
  );

  /**
   * actual server search results
   */
  const { fdocs: resources, hasNextPage, fetchNextPage } = querySearchResources;

  const searchResultFolders: LocationSelectorNodeData[] = resources
    .filter(isFolderFdoc)
    .map(folderToLocationSelectorNodeData);

  /**
   * merge local search results (spaces) and server response together
   */
  const allSearchResults: LocationSelectorNodeData[] = [
    ...searchResultSpaces,
    ...searchResultFolders,
  ];

  return (
    <LocationSelectorContext.Provider value={contextValue}>
      <SectionsWrapper>
        {searchKeyword ? (
          <>
            {allSearchResults.length === 0 ? (
              <Flex
                direction="column"
                alignItems="center"
                gap="sections"
                style={{ padding: '2rem' }}
              >
                <img src={EmptySearchIcon.src} height={120} width={120} alt="No results found" />
                <P weight={500}>No space or folder found</P>
              </Flex>
            ) : (
              allSearchResults.map((node) => (
                <Wrapper key={node.id}>
                  <LocationSelectorNode key={node.id} data={node} level={0} prevNodePath={[]} />
                </Wrapper>
              ))
            )}
          </>
        ) : (
          <Wrapper>
            {spacesMapped.map((node) => (
              <LocationSelectorNode key={node.id} data={node} level={0} prevNodePath={[]} />
            ))}
            {hasNextPage && <IntersectionObserverAnchor onIntersect={fetchNextPage} />}
          </Wrapper>
        )}
      </SectionsWrapper>
    </LocationSelectorContext.Provider>
  );
};
