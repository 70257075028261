import useDebounce from '@/src/hooks/debounce';
import { useResponsive } from '@/src/hooks/responsive';
import { useBoolState } from '@/src/hooks/useBooleanState';
import { useInputControls } from '@/src/hooks/useInputControls';
import { useObjectState } from '@/src/hooks/useObjectState';
import CloseIcon from '@/src/icons/CloseIcon';
import SearchIcon from '@/src/icons/SearchIcon';
import { useQueryResourceAncestors } from '@/src/modules/resource-detail/queries/useQueryResourceAncestors';
import { LocationSelector } from '@/src/modules/resources/components/DestinationSelector/LocationSelector';
import {
  LocationSelectorNodeData,
  LocationSelectorNodePathItem,
} from '@/src/modules/resources/components/DestinationSelector/types';
import {
  ancestorToLocationSelectorNodePathItem,
  folderToLocationSelectorNodeData,
  rootToLocationSelectorNodeData,
} from '@/src/modules/resources/components/DestinationSelector/utils';
import { ModalFolderCreate } from '@/src/modules/resources/components/ModalCreateFolder/ModalCreateFolder';
import { MoveModalNoteStack } from '@/src/modules/resources/components/ModalMoveResources/NotestackContent';
import { ResourcePath } from '@/src/modules/resources/components/ResourcePath/ResourcePath';
import { useQueryResourceList } from '@/src/modules/resources/queries/useQueryResourceList';
import { useQuerySpaceOrFolderById } from '@/src/modules/resources/queries/useQuerySpaceOrFolderById';
import { ModalSpaceCreate } from '@/src/modules/spaces/components/ModalSpaceCreate/ModalSpaceCreate';
import { Button, ButtonIcon, ButtonProps } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import Modal from '@/src/modules/ui/components/Modal';
import { ModalDrawerContent } from '@/src/modules/ui/components/Modal/Drawer';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { TextInput } from '@/src/modules/ui/components/TextInput/TextInput';
import { mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { DialogDescription, DialogTitle } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const LocationSelectorContent = styled(Modal.Content)`
  gap: 0;
  max-width: none;
  ${mediaNotMobile} {
    --modal-transform-x: 0%;
    /* transform: translateY(-50%); */
    left: auto;
    right: 2rem;
    height: 100%;
    max-height: calc(100dvh - 4rem);
    width: calc(50% - 2rem);
    min-width: 300px;
  }
`;

const HeaderContent = styled(Flex).attrs({
  direction: 'column',
})`
  flex-shrink: 0;
`;

export const ModalDestinationSelector: React.FC<{
  onClose: VoidFunction;
  open: boolean;
  /**
   * modal title, default is "Move to..."
   */
  title?: string;
  ctaButtonProps?: ButtonProps;
  onDestinationSelect: (destination: LocationSelectorNodeData) => void;
  /**
   * spaces or folders which can't be selected (and expanded)
   */
  unselectableDestinationIds?: string[];
  /**
   * list of resources from which the preview will be generated
   */
  resources?: OptimisticDraft<Fdoc>[];
  initialDestinationId?: string;
}> = ({
  resources,
  unselectableDestinationIds,
  onClose: _onClose,
  open,
  title = 'Move to...',
  onDestinationSelect,
  ctaButtonProps: ctaProps,
  initialDestinationId,
}) => {
  const { isMobileView } = useResponsive();

  const openStateCreateFolder = useBoolState(false);
  const openStateCreateSpace = useBoolState(false);
  const selectedNodeState = useObjectState<LocationSelectorNodeData>(null);
  const { value: selectedNode } = selectedNodeState;

  const scrollAreaRef = React.useRef<HTMLDivElement>(null);

  const { data: ancestors } = useQueryResourceAncestors(selectedNode?.id);

  const selectedNodePath = useMemo<LocationSelectorNodePathItem[]>(() => {
    if (!selectedNode || !ancestors) return [];

    return [
      ...ancestors.map(ancestorToLocationSelectorNodePathItem),
      {
        id: selectedNode.id,
        title: selectedNode.title,
      },
    ];
  }, [ancestors, selectedNode]);

  /**
   * on node select
   * we save the node to the state as well as update full path
   * This also sets the full path
   */
  const setSelectedNode = (node: LocationSelectorNodeData | null) => {
    selectedNodeState.set(node);
  };

  /**
   * Initial destination
   * we need to load information about the id. Once we get the data, we set it as selected node
   */

  const initialDestination = useQuerySpaceOrFolderById(initialDestinationId || '');

  // set to local component state
  React.useEffect(() => {
    if (!initialDestination || !open) {
      return;
    }

    if (initialDestination.type === 'space') {
      setSelectedNode(rootToLocationSelectorNodeData(initialDestination.data));
    }

    if (initialDestination.type === 'folder') {
      setSelectedNode(folderToLocationSelectorNodeData(initialDestination.data));
    }
    // we don't want to include setSelectedNode in the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * on close we clear all states, inform callback
   */
  const onClose = () => {
    _onClose();
    selectedNodeState.clear();
    searchKeywordInputControls.clear();
  };

  /**
   * move to target node, close modal, inform callback
   */

  /**
   * search
   */

  const searchKeywordInputControls = useInputControls('');
  const debouncedSearchKeyword = useDebounce(searchKeywordInputControls.value, 200);

  const isSearching = searchKeywordInputControls.value.length > 0;

  const queryResourceFolders = useQueryResourceList(
    {
      kind: ['folder'],
      name: debouncedSearchKeyword,
    },
    {
      enabled: debouncedSearchKeyword.length > 0,
    },
  );

  const ctaButtonProps = {
    ...ctaProps,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
      ctaProps?.onClick?.(e);
      selectedNodeState.value && onDestinationSelect(selectedNodeState.value);
      onClose();
    },
  };

  /**
   * Content of the modal
   * We render it different wrappers depending on the viewport size
   */
  const content = (
    <>
      <VisuallyHidden>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>Select destination folder or create a new one</DialogDescription>
      </VisuallyHidden>
      <Modal.Body
        style={{ gap: '1.25rem', flexShrink: 0, paddingBottom: 0 }}
        background="transparent"
      >
        <HeaderContent gap="sectionsShort" style={{ paddingBottom: 0 }}>
          <Flex
            alignItems="center"
            gap="sectionsShort"
            justifyContent="space-between"
            style={{ paddingLeft: '1rem' }}
          >
            <Modal.Title size="md-shared">{title}</Modal.Title>
            {selectedNode ? (
              <Button
                disabled={isSearching}
                variant="primary-inverse"
                onClick={openStateCreateFolder.handleTrue}
              >
                Create subfolder
              </Button>
            ) : (
              <Button
                disabled={isSearching}
                variant="primary-inverse"
                onClick={openStateCreateSpace.handleTrue}
                data-testid="move-items-create-space-button"
              >
                Create space
              </Button>
            )}
          </Flex>
          <TextInput
            {...searchKeywordInputControls}
            inputHeight="lg"
            startAdornment={<SearchIcon />}
            endAdornment={
              <Flex alignItems="center" gap="element">
                {queryResourceFolders.isFetching && <Spinner size={18} />}
                {searchKeywordInputControls.value && (
                  <ButtonIcon
                    onClick={searchKeywordInputControls.clear}
                    style={{ marginRight: -6 }}
                  >
                    <CloseIcon />
                  </ButtonIcon>
                )}
              </Flex>
            }
            placeholder="Search for a folder or space"
          />
        </HeaderContent>
      </Modal.Body>
      <ScrollArea ref={scrollAreaRef} innerDisplayFlex style={{ height: '100%' }}>
        <Modal.Body style={{ gap: '1.25rem', height: '100%' }} background="transparent">
          <LocationSelector
            searchKeyword={searchKeywordInputControls.value}
            activeNodePath={selectedNodePath || []}
            selectedNode={selectedNodeState.value}
            scrollAreaEl={scrollAreaRef.current}
            unselectableDestinationIds={unselectableDestinationIds || []}
            querySearchResources={queryResourceFolders}
            onSelectNode={setSelectedNode}
          />
        </Modal.Body>
      </ScrollArea>
    </>
  );

  return (
    <>
      <Modal open={open} onOpenChange={onClose}>
        <Modal.Portal>
          <Modal.Overlay visibleOnMobileViewport />
          <Modal.FloatingDesktopCloseButton />
          {resources && resources.length > 0 && <MoveModalNoteStack resources={resources} />}
          {isMobileView ? (
            <ModalDrawerContent
              onClose={onClose}
              aria-describedby="Select target space or folder destination"
            >
              {content}
              <Modal.Footer variant="call-to-action" style={{ marginTop: 'auto', padding: 16 }}>
                <Modal.DoubleButtonContainer style={{ width: '100%' }}>
                  <Modal.Button
                    variant="bg-secondary"
                    onClick={onClose}
                    style={{ marginRight: 'auto' }}
                  >
                    Cancel
                  </Modal.Button>
                  <Modal.Button
                    disabled={!selectedNode}
                    type="submit"
                    data-testid="move-items-modal-move-button"
                    {...ctaButtonProps}
                  />
                </Modal.DoubleButtonContainer>
              </Modal.Footer>
            </ModalDrawerContent>
          ) : (
            <LocationSelectorContent
              aria-describedby="Select target space or folder destination"
              onEscapeKeyDown={(e) => {
                if (openStateCreateSpace.value || openStateCreateFolder.value) {
                  e.preventDefault();
                }
              }}
            >
              {content}
              <Modal.Footer
                variant="call-to-action"
                background="primary"
                style={{ marginTop: 'auto' }}
              >
                <ResourcePath path={selectedNodePath} />
                <Button
                  style={{ minWidth: 100 }}
                  variant="primary-inverse"
                  disabled={!selectedNode}
                  {...ctaButtonProps}
                />
              </Modal.Footer>
            </LocationSelectorContent>
          )}
        </Modal.Portal>
      </Modal>
      <ModalFolderCreate
        modalProps={openStateCreateFolder.modalProps}
        parent={
          selectedNode
            ? {
                id: selectedNode.id,
                name: selectedNode.title,
              }
            : undefined
        }
        onCreate={(resource) => {
          setSelectedNode({
            displayType: 'folder',
            id: resource.id,
            title: resource.name || 'Untitled',
          });
        }}
      />
      <ModalSpaceCreate
        modalProps={openStateCreateSpace.modalProps}
        onCreate={(space) => {
          setSelectedNode({
            displayType: 'space',
            id: space.id,
            title: space.name,
          });
        }}
      />
    </>
  );
};
