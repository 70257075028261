import React from 'react';

import { getStyles } from '@/src/components/NoteStack/notestack.config';
import EmptyState from '@/src/icons/EmptyState';
import { ResourcePreview } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import Tilt from 'react-parallax-tilt';
import styled, { css } from 'styled-components';

const NoteStack: React.FC<{
  fdocs: OptimisticDraft<Fdoc>[];
  className?: string;
  stackSize?: number;
  noTilt?: boolean;
  scale?: number;
  opacityTransition?: boolean;
  horizontal?: boolean;
  noTransition?: boolean;
  noReorder?: boolean;
  isHoveredView?: boolean;
  /**
   * increase or decrease horizontal distance between items
   */
  horizontalSpread?: number;
  /**
   * increase or decrease vertical distance between items
   */
  verticalSpread?: number;
}> = ({
  fdocs,
  className,
  scale = 1,
  noTilt,
  horizontal = false,
  noTransition,
  isHoveredView,
  horizontalSpread = 1,
  verticalSpread = 1,
}) => {
  const items = fdocs.slice(0, Math.min(3, fdocs.length));
  return (
    <StyledTilt
      perspective={500}
      transitionSpeed={1000}
      scale={1}
      trackOnWindow={!noTilt}
      tiltMaxAngleY={10}
      tiltMaxAngleX={10}
      gyroscope={!noTilt}
      tiltEnable={!noTilt}
    >
      <List
        className={className}
        style={{
          transform: `scale(${scale})`,
        }}
        previewsCount={items.length}
        isHoveredView={isHoveredView}
        data-force-hover={isHoveredView}
      >
        {items.length > 0 ? (
          items.map((fdoc, index) => {
            return (
              <NewResourceWrapper
                key={fdoc.id}
                style={getStyles({
                  listSize: items.length,
                  transitionState: isHoveredView ? 'hovered' : 'default',
                  index,
                  noTransition,
                  horizontalSpread,
                  verticalSpread,
                  addDepth: !noTilt,
                })}
              >
                <ResourcePreview
                  resource={fdoc}
                  variant="default"
                  isSelectable={false}
                  appearence={{
                    hideMeta: true,
                  }}
                />
              </NewResourceWrapper>
            );
          })
        ) : (
          <EmptyItem
            horizontal={horizontal}
            style={{
              transition: noTransition ? 'none' : undefined,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StyledEmptyState />
          </EmptyItem>
        )}
      </List>
    </StyledTilt>
  );
};

export default NoteStack;

const NewResourceWrapper = styled.li`
  height: 240px;
  width: 260px;
  position: absolute;
  transition: 0.2s all;
  pointer-events: none;
  & > * {
    height: 240px;
    width: 260px;
  }
`;

const StyledTilt = styled(Tilt)`
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface ListProps {
  previewsCount: number;
  isHoveredView?: boolean;
}

const cssShadowPerGridItem = (strength: number = 1, spread: number = 1) => css<ListProps>`
  ${(p) => {
    switch (p.previewsCount) {
      case 1:
        return css`
          box-shadow:
            rgba(0, 0, 0, ${0.18 * strength}) 0px 2px ${spread * 17}px 0px,
            rgba(0, 0, 0, ${0.1 * strength}) 0px 2px ${spread * 3}px 0px !important;
        `;
      case 2:
        return css`
          box-shadow:
            rgba(0, 0, 0, ${0.18 * strength}) 0px 2px ${spread * 16}px 0px,
            rgba(0, 0, 0, ${0.1 * strength}) 0px 2px ${spread * 3}px 0px !important;
        `;
      default:
        return css`
          box-shadow:
            rgba(0, 0, 0, ${0.18 * strength}) 0px 2px ${spread * 14}px 0px,
            rgba(0, 0, 0, ${0.1 * strength}) 0px 2px ${spread * 3}px 0px !important;
        `;
    }
  }}
`;

const List = styled.ul<ListProps>`
  transform-style: preserve-3d;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & [data-preview-content-wrapper] {
    transition: 0.4s box-shadow;
    ${cssShadowPerGridItem(1)}
  }
  &[data-force-hover='true'] [data-preview-content-wrapper],
  &:hover [data-preview-content-wrapper] {
    ${cssShadowPerGridItem(1.4, 2)}
  }

  & [data-preview-content-wrapper='storef_file_thumbnail'] {
    border: 2.5px solid ${cssVar['color-bg-primary']};
  }

  & [data-preview-content-wrapper='stored_file_icon'] {
    background-color: ${cssVar['color-bg-primary']};
  }
`;

interface EmptyItemProps {
  horizontal?: boolean;
}
const EmptyItem = styled.li<EmptyItemProps>`
  position: absolute;
  width: 260px;
  height: 320px;
  transition: 0.3s all;

  /** some old css, might want to revisit */

  > div {
    box-shadow:
      0 4px 4px rgb(0 0 0 / 5%),
      0 8px 10px rgb(0 0 0 / 3%),
      0 8px 40px rgb(0 0 0 / 6%);
  }
  ${(p) =>
    p.horizontal &&
    css`
      > div {
        box-shadow:
          0px 1.6px 2.4px rgba(0, 0, 0, 0.1),
          0px 1.6px 13.6px rgba(0, 0, 0, 0.18);
      }
    `}
`;
const StyledEmptyState = styled(EmptyState)`
  width: 72px;
  height: 80px;
`;
