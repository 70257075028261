import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { FolderFdoc } from '@/src/types/api';
import React from 'react';

type UseQuerySpaceOrFolderByIdReturn =
  | {
      type: 'space';
      data: ResourceRoot;
      id: string;
      name: string;
    }
  | {
      type: 'folder';
      data: FolderFdoc;
      id: string;
      name: string;
    }
  | null;

export const useQuerySpaceOrFolderById = (id?: string): UseQuerySpaceOrFolderByIdReturn => {
  const { isLoading: spacesIsLoading, spaceRoots } = useQueryResourceRootSpaceSystemList(
    undefined,
    {
      includeInbox: true,
    },
  );

  const foundSpace = spaceRoots?.find((space) => space.id === id);

  const { folder, folderV2 } = useQueryFolder(id, {
    /**
     * disabled when spaces are not loaded yet
     * then check if the provided id is space. If true,we don't need to fetch the folder
     */
    enabled: !spacesIsLoading && !foundSpace,
  });

  return React.useMemo(() => {
    if (foundSpace) {
      return {
        type: 'space',
        data: foundSpace,
        id: foundSpace.id,
        name: foundSpace.folder.name || 'Untitled space',
      };
    }

    if (folderV2 && folder) {
      return {
        type: 'folder',
        data: folder,
        id: folderV2.id,
        name: folderV2.name || 'Untitled folder',
      };
    }

    return null;
  }, [folder, folderV2, foundSpace]);
};
