import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { convertResourceV2ToFdoc } from '@/src/modules/resources/utils/convertResourceV2ToFdoc';
import { useWoody } from '@/src/services/woody/woody';
import { Fdoc } from '@/src/types/api';
import { isWoodyError } from '@/src/utils/error';
import { isTruthy } from '@/src/utils/guards';
import { components, WoodyError } from '@fabric/woody-client';
import {
  InfiniteData,
  InfiniteQueryObserverOptions,
  keepPreviousData,
  QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { QueryResourceListParams, ResourceDetail } from '../resources.types';
import { resourceQueryKeys } from './resourceQueryKeys';

// These make the empty values unique so they don't cause re-renders and we don't need to memoize them
const emptyResources: ResourceDetail[] = [];
const emptyFdocs: Fdoc[] = [];
const emptyTotal = 0;

type QueryResourceListPageBase = Omit<
  components['schemas']['ResourceFilterResponse'],
  'resources'
> & {
  resources: ResourceDetail[];
};

export interface QueryResourceListPage extends QueryResourceListPageBase {
  _meta: {
    options: QueryResourceListParams;
  };
}

const filterSelector = (data: InfiniteData<QueryResourceListPage>) => {
  const allResources = data.pages.flatMap((page) => page.resources);
  return {
    total: data.pages[0].total,
    pages: data.pages.map((page) => ({
      nextCursor: page.nextCursor,
      pageTotal: page.resources.length,
    })),
    resources: allResources,
    fdocs: allResources
      .map((resource) => {
        try {
          return convertResourceV2ToFdoc(resource);
        } catch (e) {
          console.error('Error converting resource to fdoc', e);
          return null;
        }
      })
      .filter(isTruthy),
  };
};

export type QueryResourceListQueryOptions = Partial<
  InfiniteQueryObserverOptions<
    QueryResourceListPage,
    WoodyError,
    InfiniteData<QueryResourceListPage, string | undefined>,
    QueryResourceListPage,
    QueryKey,
    string | undefined
  >
>;

export type QueryResourceListDataState = InfiniteData<QueryResourceListPage>;

/**
 * fetches resources based on the provided options
 *
 * @param queryOptions
 * @returns
 */
export const useQueryResourceList = (
  options?: QueryResourceListParams,
  queryOptions?: QueryResourceListQueryOptions,
) => {
  const { client } = useWoody();

  // const isMutating = useIsMutating({
  //   mutationKey: resourceMutationKeys.createResource,
  // });

  const mergedOptions: QueryResourceListParams = {
    /**
     * default order
     */
    order: {
      property: 'modifiedAt',
      direction: 'DESC',
    },
    ...options,
  };

  // const queryDataRef = useRef<ReturnType<typeof filterSelector> | undefined>(undefined);

  // const mutatingOrEmpty = Boolean(isMutating > 0 && queryDataRef.current);

  const query = useInfiniteQuery({
    queryKey: resourceQueryKeys.resourceList(mergedOptions),
    queryFn: async ({ pageParam }) => {
      const options = {
        ...mergedOptions,
        cursor: pageParam,
      };
      const pageData = (await client.v2('/v2/resources/filter', {
        method: 'post',
        body: options,
      })) as QueryResourceListPageBase;

      return {
        ...pageData,
        // This allows the select function to have access to the options used in the query
        // And we avoid having to memoize the select function
        _meta: {
          options,
        },
      };
    },
    placeholderData: keepPreviousData,
    refetchInterval: 45000,
    retry: (failureCount, error) => {
      if (isWoodyError(error) && error.status === 404) {
        return failureCount < 1;
      }

      return failureCount < 4;
    },
    ...queryOptions,
    select: filterSelector,
    enabled: isQueryEnabled([queryOptions?.enabled]),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => {
      return (lastPage.hasMore && lastPage?.nextCursor) || undefined;
    },
  });
  // queryDataRef.current = query.data;

  return {
    ...query,
    resources: query.data?.resources || emptyResources,
    /**
     * @deprecated use `resources` instead
     */
    fdocs: query.data?.fdocs || emptyFdocs,
    total: query.data?.total ?? emptyTotal,
  };
};
